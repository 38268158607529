<template>
    <div class="topic-card" :style="containerStyle">
        <router-link tag="h2" class="topic-name" :to="{ name: 'web-app-topics', params: { topicSlug: topic.slug } }">
            {{ topic.name }}
        </router-link>
        <div class="topic-total-memos-and-follow">
            <div class="total-memos">
                <strong>{{ topic.totalMemos ? topic.totalMemos : topic.total_memos }} </strong>
                <span>Memos</span>
            </div>
            <follow-entity-button
                use-api-v2
                :entity-id="topic.id"
                :entity-name="topic.name"
                :is-following="Number(topic.is_follow)"
                entity="topics"
                @toggled-follow="(is_following) => $emit('toggled-follow', is_following)"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: "TopicCard",
    components: {
        FollowEntityButton: () => import(/* webpackChunkName: "follow-entity-button" */ "@c/atoms/follow-entity-button")
    },
    props: {
        topic: {
            type: Object,
            required: true
        },
        containerStyle: {
            type: Object,
            default: () => {}
        }
    }
}
</script>

<style lang="scss" scoped>
.topic-card {
    width: 270px;
    padding: 12px;
    background-color: #1C1C1E;
    display: flex;
    flex-direction: column;
    border-radius: 8px;

    .topic-name {
        margin-bottom: 15px;
        cursor: pointer;
    }

    .topic-total-memos-and-follow {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .total-memos {
            @extend .body-text;
            display: flex;
            align-items: baseline;
            line-height: 14px;
            color: rgba(235, 235, 245, 0.6);
            margin-right: 10px;

            strong {
                color: white;
                font-weight: 600;
                display: block;
                margin-right: 5px;
            }
        }
    }
}
</style>
